import axios, { AxiosError, AxiosResponse } from "axios";
import {
  ICreateUser,
  IEditUser,
  IEditUserPassword,
  IEditUserRoles,
  IViewDetailsUser,
  IViewListUser,
} from "@iot-uta-devices/iot-uta-devices-interfaces";
import { errorsHandler } from "@/api/utils/ErrorsHandler";
import store from "@/store";

const BASE_URL = process.env.VUE_APP_SERVER_BASE_URL;

// ToDo generic error handling.
export const getUsers = (): Promise<IViewListUser> =>
  new Promise((resolve, reject) => {
    axios
      .get(`${BASE_URL}/users`)
      .then((response: AxiosResponse<{ readonly data: IViewListUser }>) => {
        resolve(response.data.data);
      })
      .catch((error: AxiosError) => {
        let errorMessage;
        if (error.response) {
          errorMessage = error.response.data?.message || "Errore sconosciuto";
          errorsHandler(error);
          reject(new Error(errorMessage));
        } else {
          reject(error);
        }
      });
  });

export const getUser = (username: string): Promise<IViewDetailsUser> =>
  new Promise((resolve, reject) => {
    axios
      .get(`${BASE_URL}/users/${username}`)
      .then((response: AxiosResponse<{ readonly data: IViewDetailsUser }>) => {
        resolve(response.data.data);
      })
      .catch((error: AxiosError) => {
        let errorMessage;
        if (error.response) {
          errorMessage = error.response.data?.message || "Errore sconosciuto";
          errorsHandler(error);
          reject(new Error(errorMessage));
        } else {
          reject(error);
        }
      });
  });

type BasicResponse = { readonly data: string; readonly message: string };

export const postUser = (payload: ICreateUser): Promise<BasicResponse> =>
  new Promise((resolve, reject) => {
    axios
      .post(`${BASE_URL}/users`, { data: payload })
      .then((response: AxiosResponse<BasicResponse>) => {
        resolve(response.data);
        store.commit("mutation_open_snackbar_success", response.data.message);
      })
      .catch((error: AxiosError) => {
        let errorMessage;
        if (error.response) {
          errorMessage = error.response.data?.message || "Errore sconosciuto";
          errorsHandler(error);
          store.commit("mutation_open_snackbar_error", errorMessage);
        } else {
          store.commit("mutation_open_snackbar_error", error);
        }
        reject();
      });
  });

export const deleteUser = (username: string): Promise<BasicResponse> =>
  new Promise((resolve, reject) => {
    axios
      .delete(`${BASE_URL}/users/${username}`)
      .then((response: AxiosResponse<BasicResponse>) => {
        resolve(response.data);
        store.commit("mutation_open_snackbar_success", response.data.message);
      })
      .catch((error: AxiosError) => {
        let errorMessage;
        if (error.response) {
          errorMessage = error.response.data?.message || "Errore sconosciuto";
          errorsHandler(error);
          store.commit("mutation_open_snackbar_error", errorMessage);
        } else {
          store.commit("mutation_open_snackbar_error", error);
        }
        reject();
      });
  });

export const putUserDetails = (
  username: string,
  payload: IEditUser
): Promise<BasicResponse> =>
  new Promise((resolve) => {
    axios
      .put(`${BASE_URL}/users/${username}`, { data: payload })
      .then((response: AxiosResponse<BasicResponse>) => {
        resolve(response.data);
        store.commit("mutation_open_snackbar_success", response.data.message);
      })
      .catch((error: AxiosError) => {
        let errorMessage;
        if (error.response) {
          errorMessage = error.response.data?.message || "Errore sconosciuto";
          errorsHandler(error);
          store.commit("mutation_open_snackbar_error", errorMessage);
        } else {
          store.commit("mutation_open_snackbar_error", error);
        }
      });
  });

export const putUserPassword = (
  username: string,
  payload: IEditUserPassword
): Promise<BasicResponse> =>
  new Promise((resolve) => {
    axios
      .put(`${BASE_URL}/users/${username}/password`, { data: payload })
      .then((response: AxiosResponse<BasicResponse>) => {
        resolve(response.data);
        store.commit("mutation_open_snackbar_success", response.data.message);
      })
      .catch((error: AxiosError) => {
        let errorMessage;
        if (error.response) {
          errorMessage = error.response.data?.message || "Errore sconosciuto";
          errorsHandler(error);
          store.commit("mutation_open_snackbar_error", errorMessage);
        } else {
          store.commit("mutation_open_snackbar_error", error);
        }
      });
  });

export const putUserRoles = (
  username: string,
  payload: IEditUserRoles
): Promise<BasicResponse> =>
  new Promise((resolve) => {
    axios
      .put(`${BASE_URL}/users/${username}/roles`, { data: payload })
      .then((response: AxiosResponse<BasicResponse>) => {
        resolve(response.data);
        store.commit("mutation_open_snackbar_success", response.data.message);
      })
      .catch((error: AxiosError) => {
        let errorMessage;
        if (error.response) {
          errorMessage = error.response.data?.message || "Errore sconosciuto";
          errorsHandler(error);
          store.commit("mutation_open_snackbar_error", errorMessage);
        } else {
          store.commit("mutation_open_snackbar_error", error);
        }
      });
  });

export const deleteUserMachine = (
  username: string,
  machineID: string
): Promise<BasicResponse> =>
  new Promise((resolve) => {
    axios
      .delete(`${BASE_URL}/users/${username}/machines/${machineID}`)
      .then((response: AxiosResponse<BasicResponse>) => {
        resolve(response.data);
        store.commit(
          "mutation_open_snackbar_success",
          "Macchina utente eliminata con successo"
        );
      })
      .catch((error: AxiosError) => {
        let errorMessage;
        if (error.response) {
          errorMessage = error.response.data?.message || "Errore sconosciuto";
          errorsHandler(error);
          store.commit("mutation_open_snackbar_error", errorMessage);
        } else {
          store.commit("mutation_open_snackbar_error", error);
        }
      });
  });

export const deleteAllUserMachines = (
  username: string
): Promise<BasicResponse> =>
  new Promise((resolve) => {
    axios
      .delete(`${BASE_URL}/users/${username}/machines`)
      .then((response: AxiosResponse<BasicResponse>) => {
        resolve(response.data);
        store.commit("mutation_open_snackbar_success", response.data.message);
      })
      .catch((error: AxiosError) => {
        let errorMessage;
        if (error.response) {
          errorMessage = error.response.data?.message || "Errore sconosciuto";
          errorsHandler(error);
          store.commit("mutation_open_snackbar_error", errorMessage);
        } else {
          store.commit("mutation_open_snackbar_error", error);
        }
      });
  });

export const patchAllMachinesToUser = (
  username: string
): Promise<BasicResponse> =>
  new Promise((resolve) => {
    axios
      .patch(`${BASE_URL}/users/${username}/machines`)
      .then((response: AxiosResponse<BasicResponse>) => {
        resolve(response.data);
        store.commit("mutation_open_snackbar_success", response.data.message);
      })
      .catch((error: AxiosError) => {
        let errorMessage;
        if (error.response) {
          errorMessage = error.response.data?.message || "Errore sconosciuto";
          errorsHandler(error);
          store.commit("mutation_open_snackbar_error", errorMessage);
        } else {
          store.commit("mutation_open_snackbar_error", error);
        }
      });
  });

export const putMachineToUser = (
  username: string,
  machineID: string
): Promise<BasicResponse> =>
  new Promise((resolve, reject) => {
    axios
      .post(`${BASE_URL}/users/${username}/machines`, { data: machineID })
      .then((response: AxiosResponse<BasicResponse>) => {
        resolve(response.data);
        store.commit("mutation_open_snackbar_success", response.data.message);
      })
      .catch((error: AxiosError) => {
        let errorMessage;
        if (error.response) {
          errorMessage = error.response.data?.message || "Errore sconosciuto";
          errorsHandler(error);
          store.commit("mutation_open_snackbar_error", errorMessage);
        } else {
          store.commit("mutation_open_snackbar_error", error);
        }
        reject();
      });
  });
