




































































































import Vue from "vue";
import { postUser } from "@/api/UsersAPI";
import { extend, setInteractionMode } from "vee-validate";
import { min, required, email } from "vee-validate/dist/rules";

setInteractionMode("eager");

extend("required", {
  ...required,
  message: "{_field_} non può essere vuoto.",
});

extend("min", {
  ...min,
  message: "{_field_} deve contenere almeno {length} caratteri.",
});

extend("email", {
  ...email,
  message: "Indirizzo email non valido.",
});

export default Vue.extend({
  name: "CreateUserDialog",

  data: () => ({
    isOpen: false,
    username: "",
    password: Math.random().toString(36).slice(-8),
    description: "",
    email: "",
    receives_machine_error_emails: false,
    receives_machine_status_change_emails: false,
    receives_machine_scheduler_emails: false,
    loading: false,
  }),

  methods: {
    activateDialog: function () {
      this.isOpen = true;
    },
    close: function () {
      this.isOpen = false;
    },
    async submit(): Promise<void> {
      // @ts-expect-error wrong type
      const valid = await this.$refs.observer.validate();
      if (valid) {
        const payload = {
          username: this.username,
          password: this.password,
          description: this.description,
          email: this.email,
          receives_machine_error_emails: this.receives_machine_error_emails,
          receives_machine_status_change_emails: this.receives_machine_status_change_emails,
          receives_machine_scheduler_emails: this.receives_machine_scheduler_emails
        };

        this.loading = true;
        postUser(payload)
          .then((response) => {
            this.close();
            this.$router.push(`users/${response.data}`);
          })
          .catch(() => {
            // do nothing
          })
          .finally(() => {
            this.loading = false;
          });
      }
    },
  },
});
