






































































































































import Vue, { PropType } from "vue";
import router from "@/router";
import { MachineView } from "@/types/MachineView";
import UserView from "@/types/UserView";
import ActionButton from "@/globalComponents/ActionButton.vue";
import { isDateDifferenceExcessive } from "@/utils/DateHandlers";

type Headers<T extends UserView | MachineView> = Array<{
  readonly text: string;
  readonly align: "center" | "end" | "left" | "right" | "start";
  readonly value: keyof T;
}>;

// Todo: Questo componente è diventato difficile da gestire. forse è meglio fare due componenti divisi ListaMacchine e ListaUtenti.
export default Vue.extend({
  name: "List",
  components: { ActionButton },

  props: {
    data: {
      type: Array as PropType<Array<UserView | MachineView>>,
      required: true,
    },
    isLoading: {
      type: Boolean,
      required: true,
    },
    headers: {
      type: Array as PropType<Headers<UserView | MachineView>>,
      required: true,
    },
    rightButton: {
      type: Object as PropType<{
        readonly text: string;
        readonly function: () => void;
        readonly icon: string;
      }>,
      required: false,
      default: null,
    },
  },

  data: () => ({
    search: "",
    dataTableHeight: 0,
  }),

  methods: {
    updateDataTableHeight(): void {
      this.dataTableHeight = window.innerHeight - 280;
    },
    handleItemClick(item: UserView | MachineView): void {
      if ("username" in item) {
        router.push(`/users/${item.username}`);
      } else {
        router.push(`/machines/${item._id}`);
      }
    },
    isDateDifferenceExcessive(
      date: Date | null,
      key: "last_state" | "last_online"
    ): boolean {
      return isDateDifferenceExcessive(date, key);
    },
    getDateErrorColor(key: "last_state" | "last_online"): string {
      if (key === "last_state") {
        return "red";
      } else {
        return "orange";
      }
    },
    getActiveSchedulerIcon: function (item: MachineView): string {
      switch (item.active_scheduler) {
        case "pause":
          return "far fa-pause-circle";
        case "play":
          return "far fa-play-circle";
        default:
          return "";
      }
    },
  },
});
