var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{directives:[{name:"resize",rawName:"v-resize",value:(_vm.updateDataTableHeight),expression:"updateDataTableHeight"}]},[_c('v-card-title',[_c('v-row',{attrs:{"no-gutters":""}},[_c('v-col',{attrs:{"cols":"6"}},[_c('v-text-field',{staticStyle:{"margin-top":"0","padding-top":"0"},attrs:{"append-icon":"mdi-magnify","label":"Cerca","single-line":"","hide-details":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1),(_vm.rightButton)?_c('v-col',{staticClass:"text-right",attrs:{"cols":"6"}},[(_vm.$store.getters.userRoles.users_admin)?_c('action-button',{attrs:{"text":_vm.rightButton.text,"icon":_vm.rightButton.icon,"onClick":_vm.rightButton.function}}):_vm._e()],1):_vm._e()],1)],1),_c('v-card-text',[_c('v-data-table',{attrs:{"height":_vm.dataTableHeight,"fixed-header":"","hide-default-footer":"","headers":_vm.headers,"items":_vm.data,"search":_vm.search,"disable-sort":_vm.isLoading,"disable-pagination":"","sort-by":_vm.headers.find(function (h) { return h.value === 'username'; })
          ? 'username'
          : 'device_code'},on:{"click:row":_vm.handleItemClick},scopedSlots:_vm._u([{key:"no-data",fn:function(){return [_c('div',{staticClass:"mt-10"},[_vm._v(" "+_vm._s(_vm.headers.find(function (h) { return h.value === "username"; }) ? "Nessun utente da visualizzare" : "Nessuna macchina da visualizzare")+" ")])]},proxy:true},(_vm.data.length || _vm.isLoading)?{key:"body",fn:function(ref){
          var items = ref.items;
return [(!_vm.isLoading)?_c('tbody',_vm._l((items),function(item,index){return _c('tr',{key:index,class:item.status ? '' : 'unselectable-tr',on:{"click":function($event){return _vm.handleItemClick(item)}}},[('username' in item)?_vm._l((Object.keys(item).filter(
                  function (k) { return k !== 'status'; }
                )),function(key,i){return _c('td',{key:i,class:item.status ? 'selectable-td' : 'unselectable-td'},[(typeof item[key] === 'boolean')?_c('div',{staticClass:"d-flex justify-center"},[(item[key])?_c('v-icon',[_vm._v(" far fa-check-circle ")]):_vm._e()],1):_c('div',[_vm._v(" "+_vm._s(item[key])+" ")])])}):_vm._e(),('device_code' in item)?_vm._l((Object.keys(item).filter(
                  function (k) { return k !== '_id'; }
                )),function(key,i){return _c('td',{key:i,staticClass:"selectable-td"},[(typeof item[key] === 'string')?_c('div',[(key === 'active_scheduler')?_c('v-icon',{staticClass:"d-flex justify-center"},[_vm._v(" "+_vm._s(_vm.getActiveSchedulerIcon(item))+" ")]):_c('div',[_vm._v(" "+_vm._s(item[key])+" ")])],1):[(_vm.isDateDifferenceExcessive(item[key], key))?_c('div',{style:({ color: _vm.getDateErrorColor(key) })},[_vm._v(" "+_vm._s(_vm._f("normalizeDate")(item[key]))+" "),_c('v-icon',{staticClass:"pb-1",staticStyle:{"margin-left":"10px"},attrs:{"small":"","color":_vm.getDateErrorColor(key)}},[_vm._v(" fas fa-exclamation-triangle ")])],1):_c('div',[_vm._v(" "+_vm._s(_vm._f("normalizeDate")(item[key]))+" ")])]],2)}):_vm._e()],2)}),0):_vm._e(),(_vm.isLoading)?_c('tbody',_vm._l((5),function(index){return _c('tr',{key:index,staticClass:"loading-tr"},_vm._l((_vm.headers),function(key,i){return _c('td',{key:i},[_c('v-skeleton-loader',{attrs:{"loading":true,"type":"text"}})],1)}),0)}),0):_vm._e()]}}:null],null,true)})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }