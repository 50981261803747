
















import List from "@/components/List.vue";
import ComponentsCardContainer from "@/components/ComponentsCardContainer.vue";
import CreateUserDialog from "@/components/CreateUserDialog.vue";
import Vue from "vue";
import { getUsers } from "@/api/UsersAPI";
import UserView from "@/types/UserView";

export default Vue.extend({
  name: "MachineList",

  components: { List, CreateUserDialog, ComponentsCardContainer },

  data: () => ({
    users: [] as unknown as UserView[],
    loading: true,
    error: "",
    rightButton: undefined as unknown as {
      readonly text: string;
      readonly function: () => void;
      readonly icon: string;
    },
    headers: [
      {
        text: "Username",
        align: "start",
        value: "username",
      },
      {
        text: "Descrizione",
        align: "start",
        value: "description",
      },
      {
        text: "Visualizzazione Macchina",
        align: "center",
        value: "machines_viewer",
      },
      {
        text: "Programmazione macchina",
        align: "center",
        value: "machines_programmer",
      },
      {
        text: "Amministrazione macchina",
        align: "center",
        value: "machines_admin",
      },
      {
        text: "Visualizzazione utenti",
        align: "center",
        value: "users_viewer",
      },
      {
        text: "Amministrazione utenti",
        align: "center",
        value: "users_admin",
      },
    ],
  }),

  mounted: function () {
    this.error = "";
    this.loading = true;
    this.rightButton = {
      text: "Nuovo",
      icon: "fas fa-plus",
      function: () => {
        // @ts-expect-error wrong type
        this.$refs.createUserDialog.activateDialog();
      },
    };
    getUsers()
      .then((response) => {
        this.users = response.map((user) => ({
          username: user.username,
          description: user.description || "Non disponibile",
          machines_viewer: user.roles.machines_viewer,
          machines_programmer: user.roles.machines_programmer,
          machines_admin: user.roles.machines_admin,
          users_viewer: user.roles.users_viewer,
          users_admin: user.roles.users_admin,
          status: user.status,
        }));
      })
      .catch((error) => {
        this.error = error;
      })
      .finally(() => {
        this.loading = false;
      });
  },
});
