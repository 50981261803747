var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-dialog',{attrs:{"max-width":"500px"},model:{value:(_vm.isOpen),callback:function ($$v) {_vm.isOpen=$$v},expression:"isOpen"}},[_c('v-card',[_c('v-card-title',[_vm._v(" Creazione nuovo utente ")]),_c('v-card-text',[_c('div',{staticClass:"mx-5",on:{"keyup":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }return _vm.submit.apply(null, arguments)}}},[_c('validation-observer',{ref:"observer"},[_c('v-form',{on:{"submit":function($event){$event.preventDefault();return _vm.submit.apply(null, arguments)}}},[_c('validation-provider',{attrs:{"name":"Username","rules":"required|min:4"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"label":"Username","type":"text","validate-on-blur":"","error-messages":errors,"autocomplete":"false"},model:{value:(_vm.username),callback:function ($$v) {_vm.username=$$v},expression:"username"}})]}}])}),_c('validation-provider',{attrs:{"name":"Password","rules":"required|min:8"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"label":"Password","type":"text","validate-on-blur":"","autocomplete":"false","error-messages":errors},model:{value:(_vm.password),callback:function ($$v) {_vm.password=$$v},expression:"password"}})]}}])}),_c('validation-provider',{attrs:{"name":"Descrizione","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"label":"Descrizione","type":"text","validate-on-blur":"","error-messages":errors,"autocomplete":"false"},model:{value:(_vm.description),callback:function ($$v) {_vm.description=$$v},expression:"description"}})]}}])}),_c('validation-provider',{attrs:{"name":"Email","rules":"email"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"label":"Email","type":"email","validate-on-blur":"","error-messages":errors,"autocomplete":"false"},model:{value:(_vm.email),callback:function ($$v) {_vm.email=$$v},expression:"email"}})]}}])}),_c('v-checkbox',{attrs:{"label":"Ricezione email errori macchina"},model:{value:(_vm.receives_machine_error_emails),callback:function ($$v) {_vm.receives_machine_error_emails=$$v},expression:"receives_machine_error_emails"}}),_c('v-checkbox',{attrs:{"label":"Ricezione email macchina offline/online"},model:{value:(_vm.receives_machine_status_change_emails),callback:function ($$v) {_vm.receives_machine_status_change_emails=$$v},expression:"receives_machine_status_change_emails"}}),_c('v-checkbox',{attrs:{"label":"Ricezione email sospensione programmazione"},model:{value:(_vm.receives_machine_scheduler_emails),callback:function ($$v) {_vm.receives_machine_scheduler_emails=$$v},expression:"receives_machine_scheduler_emails"}})],1)],1)],1)]),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"color":"error","text":"","disabled":_vm.loading},on:{"click":_vm.close}},[_vm._v(" Annulla ")]),_c('v-btn',{attrs:{"color":"success","text":"","disabled":_vm.loading,"loading":_vm.loading},on:{"click":_vm.submit}},[_vm._v(" Salva ")]),_c('v-spacer')],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }